import { useMutation } from '@tanstack/react-query'
import { auth, call } from './service'

interface Call {
  ramal: string
  phoneNumber: string
}

export const phoneCall = async ({ ramal, phoneNumber }: Call) => {
  let token = ''

  try {
    token = await auth()
  } catch (error) {
    const message = 'Could not authenticate in ura service'

    console.error(message)

    throw new Error(message)
  }

  const response = await call({ ramal, phoneNumber, token })

  return response
}

export const usePhoneCall = () => {
  return useMutation({
    mutationFn: async (data: Call) => await phoneCall(data),
  })
}
